import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { pathFromAdminRoute } from "@adltools/adminui";
import { UiConfig } from "../adl-gen/whistle/xsync/uiconfig";
import { ApiServices } from "../service/api-services";
import { Protected } from "../ui/common/protected";
import { AdminUi } from "../ui2/page/AdminUi";
import { LoginPage } from "../ui2/page/LoginPage";

import { LoginState, useLoginState } from "./identity-state";
import { MainPage } from "../ui2/page/MainPage";
import { LoggedOutPage } from "../ui2/page/LoggedOut";
import { UserLayout } from "../ui2/layout/UserLayout";
import CircularProgress from "@mui/material/CircularProgress";
import { WelcomePage } from "../ui2/page/WelcomePage";
import { RegisterPage } from "../ui2/page/RegisterPage";
import { AppRoutes } from "./AppRoutes";
import Container from "@mui/material/Container";
import { ForgotPasswordPage } from "../ui2/page/ForgotPasswordPage";
import { ResetPasswordPage } from "../ui2/page/ResetPasswordPage";
import { PaymentPage } from "../ui2/page/Payment";
import { SubscriptionPage } from "../ui2/page/SubscriptionPage";
import { OrganisationsPage } from "../ui2/page/OrganisationsPage";
import { ExportDataPage } from "../ui2/page/ExportDataPage";
import { PublicSetupLayout } from "../ui2/layout/PublicSetupLayout";


export interface LoggedInContextProps {
  loginState?: LoginState;
}

export const LoggedInContext = React.createContext<LoggedInContextProps>({});

interface AppProps {
  /** UI configuration */
  uiconfig: UiConfig;
  createApiServices(token?: string): ApiServices;
}

const CallbackComponent = () => {
  const params = new URLSearchParams(location.search);

  // tslint:disable-next-line:no-console
  console.log(params.get("code"));

  return <div>foo</div>;
};

export const App = (props: AppProps) => {
  const loginState = useLoginState({
    createApiServices: props.createApiServices
  });

  return (
    <>
      {loginState.userProfileIsLoading ? (
        <PublicSetupLayout>
          <Container maxWidth="xs">
            <CircularProgress/>
          </Container>
        </PublicSetupLayout>
      ) : (
        <Switch>
          <Route path={AppRoutes.Login}>
            <LoginPage
              loginState={loginState}
              errorMessage={loginState.loginError}
              onLogin={loginState.onLogin}
            />
          </Route>
          <Route path={AppRoutes.Logout}>
            <LoggedOutPage />
          </Route>
          <Route path={AppRoutes.Register}>
            <RegisterPage />
          </Route>
          <Route path={AppRoutes.ForgotPassword}>
            <ForgotPasswordPage />
          </Route>
          <Route path={`${AppRoutes.ResetPassword}/:token`}>
            <ResetPasswordPage />
          </Route>
          <Route path={`${AppRoutes.Welcome}/:token`}>
            <WelcomePage />
          </Route>
          <Protected loginState={loginState}>
            <LoggedInContext.Provider
              value={{
                loginState
              }}
            >
              <Route exact path={AppRoutes.Index}>
                <MainPage />
              </Route>
              <Route exact path={AppRoutes.Payment}>
                <PaymentPage />
              </Route>
              <Route exact path={AppRoutes.Subscription}>
                <SubscriptionPage/>
              </Route>
              <Route exact path={AppRoutes.Organisations}>
                <OrganisationsPage/>
              </Route>
              <Route exact path={AppRoutes.ExportData}>
                <ExportDataPage/>
              </Route>
              
              <Route exact path={AppRoutes.Admin}>
                <Redirect
                  to={`${AppRoutes.Admin}${pathFromAdminRoute({
                    route: "main"
                  })}`}
                />
              </Route>
              <Route path={AppRoutes.Admin}>
                <UserLayout>
                  <AdminUi />
                </UserLayout>
              </Route>
              <Route exact path={AppRoutes.XeroAuthCallback}>
                <CallbackComponent></CallbackComponent>
              </Route>
              <Route path={AppRoutes.NotFound}>
                <Redirect to={AppRoutes.Index} />
              </Route>
            </LoggedInContext.Provider>
          </Protected>
        </Switch>
      )}
    </>
  );
};
