// tslint:disable: no-unused-variable
// tslint:disable: ordered-imports
import { ApiRequests, snApiRequests, makeApiRequests, LoginReq, LoginResp, RegisterReq, RegisterResp, ActivateReq, ActivateResp, PaymentSuccess, SuccessOrError, ForgotPasswordReq, ResetPasswordReq, NewMessageReq, RecentMessagesReq, Message, UserProfile, OrgListEntry, GetApiKeyValue, ListProductsResp, CreateCheckoutSessionReq, CreateCheckoutSessionResp, CheckSubscriptionReq, CheckSubscriptionResp } from "./whistle/xsync/api";
import { Unit, Paginated } from "./common";
import { DbKey } from "./common/db";
import { XeroOrganisation } from "./whistle/xsync/db";
import { HttpServiceBase } from "@adltools/service/http-service-base";
import { HttpServiceError } from "@adltools/service/http-service-error";
import { GetFn, PostFn } from "@adltools/service/types";
import { HttpFetch } from "@hx/hx/service/http";

import { DeclResolver } from "./runtime/adl";

/** The app API */
export class AppService extends HttpServiceBase {
  /** Login a user */
  postLogin: PostFn<LoginReq, LoginResp>;

  /** Register a user */
  postRegister: PostFn<RegisterReq, RegisterResp>;

  /** Activate a user */
  postActivate: PostFn<ActivateReq, ActivateResp>;

  postPaymentSuccess: PostFn<PaymentSuccess, SuccessOrError>;

  postForgotPassword: PostFn<ForgotPasswordReq, SuccessOrError>;

  postResetPassword: PostFn<ResetPasswordReq, SuccessOrError>;

  /** Post a message to the noticeboard */
  postNewMessage: PostFn<NewMessageReq, Unit>;

  /** Get recent noticeboard messages */
  postRecentMessages: PostFn<RecentMessagesReq, Paginated<Message>>;

  /** Gets the logged in user details */
  getWhoAmI: GetFn<UserProfile>;

  /** Gets the list of organisations accessible for current user */
  getGetOrganisations: GetFn<OrgListEntry[]>;

  postGetApiKeys: PostFn<Unit, GetApiKeyValue[]>;

  postXeroDisconnect: PostFn<DbKey<XeroOrganisation>, Unit>;

  /** Get redirect URL to go to xero for authorization */
  getXeroAuthorization: GetFn<string>;

  /** Run once through a sync */
  postXeroSyncOnce: PostFn<Unit, Unit>;

  /** Run once through a sync for a single org */
  postXeroSyncSingleOrg: PostFn<DbKey<XeroOrganisation>, Unit>;

  postListProducts: PostFn<Unit, ListProductsResp>;

  postCreateCheckoutSession: PostFn<CreateCheckoutSessionReq, CreateCheckoutSessionResp>;

  postCheckSubscription: PostFn<CheckSubscriptionReq, CheckSubscriptionResp>;

  constructor(
    /** Fetcher over HTTP */
    http: HttpFetch,
    /** Base URL of the API endpoints */
    baseUrl: string,
    /** Resolver for ADL types */
    resolver: DeclResolver,
    /** The authentication token (if any) */
    authToken: string | undefined,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    handleError: (error: HttpServiceError) => void
  ) {
    super(http, baseUrl, resolver, authToken, handleError);
    const api = this.annotatedApi(snApiRequests, makeApiRequests({}));
    this.postLogin = this.mkPostFn(api.login);
    this.postRegister = this.mkPostFn(api.register);
    this.postActivate = this.mkPostFn(api.activate);
    this.postPaymentSuccess = this.mkPostFn(api.paymentSuccess);
    this.postForgotPassword = this.mkPostFn(api.forgotPassword);
    this.postResetPassword = this.mkPostFn(api.resetPassword);
    this.postNewMessage = this.mkPostFn(api.newMessage);
    this.postRecentMessages = this.mkPostFn(api.recentMessages);
    this.getWhoAmI = this.mkGetFn(api.whoAmI);
    this.getGetOrganisations = this.mkGetFn(api.getOrganisations);
    this.postGetApiKeys = this.mkPostFn(api.getApiKeys);
    this.postXeroDisconnect = this.mkPostFn(api.xeroDisconnect);
    this.getXeroAuthorization = this.mkGetFn(api.xeroAuthorization);
    this.postXeroSyncOnce = this.mkPostFn(api.xeroSyncOnce);
    this.postXeroSyncSingleOrg = this.mkPostFn(api.xeroSyncSingleOrg);
    this.postListProducts = this.mkPostFn(api.listProducts);
    this.postCreateCheckoutSession = this.mkPostFn(api.createCheckoutSession);
    this.postCheckSubscription = this.mkPostFn(api.checkSubscription);
  }

  /** Login a user */
  async login(req: LoginReq): Promise<LoginResp> {
    return this.postLogin.call(req);
  }

  /** Register a user */
  async register(req: RegisterReq): Promise<RegisterResp> {
    return this.postRegister.call(req);
  }

  /** Activate a user */
  async activate(req: ActivateReq): Promise<ActivateResp> {
    return this.postActivate.call(req);
  }

  async paymentSuccess(req: PaymentSuccess): Promise<SuccessOrError> {
    return this.postPaymentSuccess.call(req);
  }

  async forgotPassword(req: ForgotPasswordReq): Promise<SuccessOrError> {
    return this.postForgotPassword.call(req);
  }

  async resetPassword(req: ResetPasswordReq): Promise<SuccessOrError> {
    return this.postResetPassword.call(req);
  }

  /** Post a message to the noticeboard */
  async newMessage(req: NewMessageReq): Promise<Unit> {
    return this.postNewMessage.call(req);
  }

  /** Get recent noticeboard messages */
  async recentMessages(req: RecentMessagesReq): Promise<Paginated<Message>> {
    return this.postRecentMessages.call(req);
  }

  /** Gets the logged in user details */
  async whoAmI(): Promise<UserProfile> {
    return this.getWhoAmI.call();
  }

  /** Gets the list of organisations accessible for current user */
  async getOrganisations(): Promise<OrgListEntry[]> {
    return this.getGetOrganisations.call();
  }

  async getApiKeys(req: Unit): Promise<GetApiKeyValue[]> {
    return this.postGetApiKeys.call(req);
  }

  async xeroDisconnect(req: DbKey<XeroOrganisation>): Promise<Unit> {
    return this.postXeroDisconnect.call(req);
  }

  /** Get redirect URL to go to xero for authorization */
  async xeroAuthorization(): Promise<string> {
    return this.getXeroAuthorization.call();
  }

  /** Run once through a sync */
  async xeroSyncOnce(req: Unit): Promise<Unit> {
    return this.postXeroSyncOnce.call(req);
  }

  /** Run once through a sync for a single org */
  async xeroSyncSingleOrg(req: DbKey<XeroOrganisation>): Promise<Unit> {
    return this.postXeroSyncSingleOrg.call(req);
  }

  async listProducts(req: Unit): Promise<ListProductsResp> {
    return this.postListProducts.call(req);
  }

  async createCheckoutSession(req: CreateCheckoutSessionReq): Promise<CreateCheckoutSessionResp> {
    return this.postCreateCheckoutSession.call(req);
  }

  async checkSubscription(req: CheckSubscriptionReq): Promise<CheckSubscriptionResp> {
    return this.postCheckSubscription.call(req);
  }
};
