/** All routes in the app */

export enum AppRoutes {
  Index = "/",

  Register = "/register",
  Login = "/login",
  Logout = "/logout",
  Welcome = "/welcome",
  ForgotPassword = "/forgotpw",
  ResetPassword = "/reset",

  Admin = "/admin",
  NotFound = "/not-found",

  // Export data and API keys
  ExportData = "/export",

  // Organisations
  Organisations = "/organisations",

  // Page for initiating subscription
  Subscription = "/subscription",

  // (Page that catches success or cancelled payment)
  Payment = "/payment",

  XeroAuthCallback = "/xero_auth_callback"
}
