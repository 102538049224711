import React, { Dispatch, SetStateAction, useReducer, useState } from "react";

import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { LoginReq } from "../../../adl-gen/whistle/xsync/api";
import produce from "immer";
import Grid from "@mui/material/Grid";
import { AppRoutes } from "../../../app/AppRoutes";
import { useHistory } from "react-router-dom";

export interface LoginProps {
  errorMessage?: string;
  onLogin: (req: LoginReq) => void;
}

export function Login(props: LoginProps) {
  const history = useHistory();
  const [val, setVal] = useState<Partial<LoginReq>>({});

  function isValid(val: Partial<LoginReq>): LoginReq | null {
    if (
      val.email &&
      val.email.trim() !== "" &&
      val.password &&
      val.password.trim() !== ""
    ) {
      return {
        email: val.email,
        password: val.password
      };
    }
    return null;
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const req = isValid(val);
    if (req && props.onLogin) {
      props.onLogin(req);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          type="email"
          label="Email"
          value={val.email ?? ""}
          onChange={ev => {
            setVal(
              produce(val, draft => {
                draft.email = ev.target.value;
              })
            );
          }}
        />
        <TextField
          type="password"
          label="Password"
          value={val.password ?? ""}
          onChange={ev => {
            setVal(
              produce(val, draft => {
                draft.password = ev.target.value;
              })
            );
          }}
        />
        {props.errorMessage && (
          <Alert severity="error">{props.errorMessage}</Alert>
        )}
        <Button type="submit" variant="contained" disabled={!isValid(val)}>
          Login
        </Button>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button type="submit" variant="outlined" onClick={()=>{
              history.push(AppRoutes.Register);
            }}>
              Register New User
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="outlined" onClick={()=>{
              history.push(AppRoutes.ForgotPassword);
            }}>
              Forgot Password
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}
