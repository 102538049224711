import React, { useEffect } from "react";
import { LoginReq } from "../../adl-gen/whistle/xsync/api";
import { Login } from "../components/login/Login";

import Box from "@mui/material/Box";
import { Layout } from "../layout/Layout";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import { PublicLayout } from "../layout/PublicLayout";
import { LoginState } from "../../app/identity-state";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../app/AppRoutes";

export interface LoginPageProps {

  loginState: LoginState;

  /** Error message to show upon login failure */
  errorMessage?: string;

  /** Callback to login */
  onLogin(req: LoginReq): void;
}

export function LoginPage(props: LoginPageProps) {
  const history = useHistory();
  
  useEffect(()=>{
    if(props.loginState.user) {
      history.push(AppRoutes.Index);
    }
  },[props.loginState.user]);

  return <PublicLayout>
    <Container maxWidth={"xs"}>
      <Stack spacing={1}>
        <Login {...props} />
      </Stack>
    </Container>
  </PublicLayout>;
}
