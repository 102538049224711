import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { service } from "../../service/makeApiServices";
import { assertNever } from "../../tslibs/hx/src/util/types";
import { PublicLayout } from "../layout/PublicLayout";
import { AppRoutes } from "../../app/AppRoutes";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

export function RegisterPage() {
  return <PublicLayout>
    <Container maxWidth={"xs"}>
      <Stack spacing={1}>
        <Register/>
      </Stack>
    </Container>
  </PublicLayout>;
}

export function Register() {
  const history = useHistory();
  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  type State = "initial"|"success"|"error";
  const [state, setState] = useState<State>("initial");

  const [loading, setLoading] = useState<boolean>(false);

  if(loading) {
    return <Container maxWidth="xs">
      <CircularProgress />
    </Container>;
  }

  switch(state) {
    case 'initial':
    {
      const isValid = fullName && email;

      return <Stack spacing={1}>
        <Typography>
          Please Register as a new user.
        </Typography>
        
        <TextField
          id="invite-user-fullname"
          label="Name"
          type="text"
          value={fullName}
          onChange={(ev)=>setFullName(ev.target.value)}
        />
        <TextField
          id="invite-user-email"
          label="Email"
          type="email"
          value={email}
          onChange={(ev)=>setEmail(ev.target.value)}
        />
        <Button disabled={!isValid} variant="contained" color="primary"
          onClick={async ()=>{
            setLoading(true);
            const resp = await service.app.register({
              email,
              fullName
            });
            setLoading(false);
    
            if(resp === 'success') {
              setState("success");
            } else {
              setState("error");
            }
          }}
        >
          Register
        </Button>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button type="submit" variant="outlined" onClick={()=>{
              history.push(AppRoutes.Login);
            }}>
              Existing User Login
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="outlined" onClick={()=>{
              history.push(AppRoutes.ForgotPassword);
            }}>
              Forgot Password
            </Button>
          </Grid>
        </Grid>
      </Stack>;  
    }  
    
    case 'success': {
      return <Stack spacing={1}>
        <Alert severity="success">Success - Please check your email to continue registering</Alert>
      </Stack>;
    }
    
    case 'error':
      return <div>error</div>

    default:
      assertNever(state)
  }
}