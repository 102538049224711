import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { assertNever } from "@hx/util/types";
import { LoginReq, UserProfile } from "../adl-gen/whistle/xsync/api";
import { ApiServices } from "../service/api-services";

import { AppRoutes } from "./AppRoutes";

export type LoginRespType =
  | { kind: "logged-in" }
  | { kind: "login-failed"; error: string };

export interface IdentityState {
  /** The authenticated users details */
  profile: UserProfile;
  /** The http APIs authenticated for the current user  */
  apis: ApiServices;
}

export interface LoginState {
  user: IdentityState | undefined;
  loginError: string | undefined;

  onLogin(req: LoginReq): void;
  onLogout(): void;
  /** A boolean state tracking whether the user profile has loaded. */
  userProfileIsLoading: boolean;
}

export interface LoginStateProps {
  createApiServices(token?: string): ApiServices;
}

/**
 * Manages login state with react hooks
 */
export function useLoginState(props: LoginStateProps): LoginState {
  const [loginError, setLoginError] = React.useState<string | undefined>(
    undefined
  );
  const [user, setUser] = React.useState<IdentityState | undefined>(undefined);
  const [userProfileIsLoading, setUserProfileIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  async function loadUserState(authToken: string) {
    const apis = props.createApiServices(authToken);
    const profile = await apis.app.whoAmI();
    setUser({ apis, profile });
  }

  async function onLogin(req: LoginReq): Promise<LoginRespType> {
    const apis0 = props.createApiServices();
    const r = await apis0.app.login(req);

    if (r.kind === "invalidCredentials") {
      const errorStr = "Invalid username and/or password";
      setLoginError(errorStr);
      return { kind: "login-failed", error: errorStr };
    } else if (r.kind === "accessToken") {
      setLoginError(undefined);
      const authToken = r.value;
      await loadUserState(authToken);
      storeLocalToken(authToken);
      return { kind: "logged-in" };
    } else {
      assertNever(r);
    }
  }

  async function onLoginWithRedirect(req: LoginReq): Promise<void> {
    const resp = await onLogin(req);
    if (resp.kind === "logged-in") {
      const params = new URLSearchParams(location.search);
      // If the login was from a redirect
      if (params.has("referrer")) {
        location.pathname = params.get("referrer") || "";
        params.delete("referrer");
        location.search = params.toString();
      } else {
        location.pathname = AppRoutes.Index;
      }
      history.push(location);
    }
  }

  function onLogout(): void {
    setUser(undefined);
    history.push(AppRoutes.Logout);
    clearLocalToken();
  }

  // On initial mount, load the logged in user profile.
  useEffect(() => {
    const token = getLocalToken();
    // If the user is logged out, then stop loading and don't attempt to load the user profile.
    if (token === null || token.length < 0) {
      setUserProfileIsLoading(false);
    } else {
      void loadUserState(token);
    }
  }, []);

  // When the logged in user profile changes, check if it is defined and set
  // update the user profile loading state tracker accordingly.
  useEffect(() => {
    if (user) {
      setUserProfileIsLoading(false);
    }
  }, [user]);

  return {
    user,
    loginError,
    onLogin: onLoginWithRedirect,
    onLogout,
    userProfileIsLoading
  };
}

export function storeLocalToken(authToken: string) {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
}

export function getLocalToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY);
}

export function clearLocalToken() {
  localStorage.removeItem(AUTH_TOKEN_KEY);
}

const AUTH_TOKEN_KEY: string = "propte-access-token";
